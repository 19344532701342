














import UserProfileForm from "@/components/users/UserProfileForm.vue";
import { BaseUser } from "@sportango/backend";
import { MISSING_USER_IMAGE } from "@/utils/constants";
import { CurrentUserMixin } from "@/mixins/Helpers";
import Component, { mixins } from "vue-class-component";

@Component({
  name: "add-profile",
  components: {
    UserProfileForm
  }
})
export default class AddProfile extends mixins(CurrentUserMixin) {
  get newUserBase(): Partial<BaseUser> {
    return {
      uid: `${this.currentUser?.uid}-${this.profiles.length}`,
      permissions: this.currentUser?.permissions,
      photoURL: MISSING_USER_IMAGE
    };
  }
}
